<template>
    <modal ref="modalDetalleCarpeta" titulo="Detalle" guardar tamano="modal-md">
        <div v-if="showData">
            <div class="row mx-0">
                <div class="col-auto">
                    <img src="/img/generales/CarpetaColor.svg" class="br-4" style="width:69px;height: 69px;" />
                    <span class="ml-2">{{ detalle.nombre }}</span>
                </div>
            </div>
            <div class="row mx-1 mt-3">
                <div class="col">
                    <p class="f-500">Creación</p>
                    <p class="f-14 ml-1">{{ detalle.created_at | helper-fecha('DD MMMM [de] YYYY') }}</p>
                </div>
            </div>
            <div class="row mx-1 mt-3">
                <div class="col">
                    <p class="f-500">archivos</p>
                    <p class="f-14 ml-1">{{ detalle.cant_archivos }}</p>
                </div>
            </div>
            <div class="row mx-1 mt-3">
                <div class="col">
                    <p class="f-500">privacidad</p>
                    <div class="col-auto">
                        <i :class="`${iconTipo(detalle.tipo)}`" /> <span
                        class="text-black f-14 ml-1"
                        >{{ textTipo(detalle.tipo) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row mx-0">
                <div class="col text-center">
                    <p class="f-13">Cargando información...</p>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            detalle: {},
            showData: false
        }
    },

    methods: {
        toggle(data){
            this.detalle = data;
            this.$refs.modalDetalleCarpeta.toggle();
            this.showData = true;
        },
        iconTipo(tipo){
            switch (tipo){
            case 1:
                return ''
            case 2:
                return 'icon-group-outline'
            case 3:
                return 'icon-account-plus'
            }
        },
        textTipo(tipo){
            switch (tipo){
            case 1:
                return 'Privada'
            case 2:
                return 'Pública'
            case 3:
                return 'Compartida'
            }
        },

    }
}
</script>
