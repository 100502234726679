<template>
	<section class="px-5 pt-3">
		<cargando v-if="cargando" />
		<div class="row mx-0 mb-4">
			<div class="col-auto px-0 text-black f-500 f-20">
				Gestión Administrativa
			</div>
			<div class="btn-general ml-auto px-3 cr-pointer br-4" @click="goCreate">
				<p class="py-1">Nueva Carpeta</p>
			</div>
			<div class="col-3">
				<el-input v-model="buscar" prefix-icon="icon-search f-16" size="small" placeholder="Buscar"
					class="w-100" />
			</div>
		</div>
		<div class="row mx-0">
			<div class="col-auto px-0 text-black f-14">
				<p>{{ carpetas.length }} Carpetas</p>
			</div>
			<div class="col-auto ml-auto cr-pointer" @click="cambioVista(vista)">
				<i v-if="vista == 1" class="icon-list-bullet f-16" />
				<i v-else class="icon-th f-16" />
			</div>
		</div>
		<hr />
		<vista-iconos v-if="vista == 1" :carpetas="carpetas" :search="buscar" @eliminar="eliminarCarpeta"
			@actualizar="editarNombre" @privacidad="goEditPrivacidad" @detalle="verDetalle"/>
		<vista-lista v-else :carpetas="carpetas" :search="buscar" @eliminar="eliminarCarpeta"
			@actualizar="editarNombre" @privacidad="goEditPrivacidad" />

		<modal-eliminar ref="modalEliminarCarpeta" titulo="Eliminar carpeta" mensaje="¿Desea eliminar la carpeta?"
			tamano="modal-md" class="f-15 text-center" @eliminar="eliminarCarpetaConfirm" />

		<modal ref="modalEditarNombre" titulo="Cambiar nombre de carpeta" adicional="guardar" tamano="modal-md"
			@adicional="editNombreCarpeta">
			<div class="container">
				<ValidationObserver tag="div" ref="form">
					<div class="row justify-content-center mx-0 py-2">
						<ValidationProvider tag="div" class="w-100" v-slot={errors} rules="required" name="comentario">
							<el-input placeholder="nombre" v-model="nombre" type="input" />
							<p class="text-danger f-10">{{ errors[0] }}</p>
						</ValidationProvider>
					</div>
				</ValidationObserver>
			</div>
		</modal>
		<ModalDetalleCarpeta ref="modaldetalleCarpeta" />
	</section>
</template>

<script>
import Carpetas from '~/services/bibliotecas/bibliotecas-carpetas'
import ModalDetalleCarpeta from './partials/modalDetalleCarpeta.vue';
export default {
	components: {
    vistaIconos: () => import("./components/carpetasVistaIconos.vue"),
    vistaLista: () => import("./components/carpetasVistaLista.vue"),
    ModalDetalleCarpeta
},
	data() {
		return {
			cargando: false,
			buscar: '',
			vista: 1,
			carpetas: [],
			idCarpeta: null,
			nombre: ''
		}
	},

	mounted() {
		this.getCarpetas();
	},

	methods: {
		verDetalle(item) {
			let carpeta = this.carpetas[item]
			this.$refs.modaldetalleCarpeta.toggle(carpeta)
		},

		cambioVista(item) {
			this.vista = item == 1 ? 2 : 1;
		},

		goCreate(){
			this.$router.push({ name:'biblioteca.carpetas.crear'})
		},

		goEditPrivacidad(item){
			this.$router.push({ name:'biblioteca.carpetas.editar.privacidad', params: { idCarpeta: item.id, privacidad: item.tipo }})
		},

		async getCarpetas() {
			try {
				this.cargando = true;
				const { data } = await Carpetas.getListaCarpetas()
				this.carpetas = data.carpetas;

			} catch (error) {
				return this.errorCatch(error);
			} finally {
				this.cargando = false;
			}
		},

		eliminarCarpeta(item) {
			this.idCarpeta = item
			this.nombre = item.nombre
			this.$refs.modalEliminarCarpeta.toggle()
		},

		editarNombre(item) {
			this.idCarpeta = item.id
			this.nombre = item.nombre
			this.$refs.modalEditarNombre.toggle()
		},

		async eliminarCarpetaConfirm() {
			try {
				const { data } = await Carpetas.eliminarCarpeta(this.idCarpeta)
				this.notificacion('Exito', data.mensaje, 'success')
				this.getCarpetas();
				this.idCarpeta = null;
				this.$refs.modalEliminarCarpeta.toggle()

			} catch (error) {
				return this.errorCatch(error);
			}
		},

		async editNombreCarpeta() {
			try {

				let validate = await this.$refs.form.validate()
				if (!validate) return this.notificacion('', 'Por favor escriba un nombre')

				const payload = {
					nombre: this.nombre,
					idCarpeta: this.idCarpeta
				}
				const { data } = await Carpetas.editarNombreCarpeta(payload)
				this.notificacion('Exito', data.mensaje, 'success')
				this.idCarpeta = null;
				this.nombre = '';
				this.getCarpetas();
				this.$refs.modalEditarNombre.toggle()
			} catch (error) {
				return this.errorCatch(error);
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.card-vigilante {
	width: 281px;
	height: 82px;
	border-radius: 4px;
	border: 1px solid #DBDBDB;
	background-color: #FCFCFC;

	&:hover {
		box-shadow: 0px 3px 6px #00000029;
	}
}

.card-ventana {
	width: 330px;
	height: 90px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #DBDBDB;
}
</style>